import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './home.css';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Loading from "../../Loading/Loading";

function Home() {
	const [posts, setPosts] = useState([]); // State to hold multiple posts
	const [latestPosts, setLatestPosts] = useState([]); // State for latest 6 posts
	const [slideshowCategoryId, setSlideshowCategoryId] = useState(null); // Save slideshow category ID
	const [defaultCategorySlug, setDefaultCategorySlug] = useState(null);

	const navigate = useNavigate(); // Use the navigate hook for programmatic navigation

	useEffect(() => {
		async function fetchRandomPosts() {
			try {
				const themeOptionsResponse = await axios.get(
					`${global.config["globals"].WpApiUrl}/wp/v2/theme-options/get`
				);
				// Extract the slideshow_category ID from the response
				const slideshowCategoryId = themeOptionsResponse.data?.slideshow_category?.id;
				const defaultCategorySlug = themeOptionsResponse.data?.default_category?.slug;

				console.log("Slideshow Category ID:", slideshowCategoryId);
				console.log("Default Category Slug:", defaultCategorySlug);

				setSlideshowCategoryId(slideshowCategoryId);
				setDefaultCategorySlug(defaultCategorySlug);

				if (!slideshowCategoryId) {
					console.error('Slideshow category ID not found.');
					return;
				}

				// Save slideshowCategoryId in state
				setSlideshowCategoryId(slideshowCategoryId);

				// Fetch posts with embedded data
				const response = await axios.get(`${global.config["globals"].WpApiUrl}/wp/v2/posts/`, {
					params: {
						categories: slideshowCategoryId,
						per_page: 100,
						_embed: true
					},
				});

				if (response.data && response.data.length > 0) {
					// Shuffle and get 10 random posts
					const shuffledPosts = response.data.sort(() => 0.5 - Math.random()).slice(0, 10);
					setPosts(shuffledPosts);
				}
			} catch (error) {
				console.error('Error fetching posts:', error);
			}
		}
		fetchRandomPosts();
	}, []);

	async function fetchCategoryDetails(categoryId) {
		try {
			const categoryResponse = await axios.get(
				`${global.config["globals"].WpApiUrl}/wp/v2/categories/${categoryId}`
			);
			return {
				id: categoryId,
				name: categoryResponse.data.name,
				slug: categoryResponse.data.slug,
			};
		} catch (error) {
			console.error(`Error fetching category details for ID ${categoryId}:`, error);
			return {
				id: categoryId,
				name: null,
				slug: null,
			};
		}
	}

	useEffect(() => {
		async function fetchLatestPosts() {
			try {
				const response = await axios.get(`${global.config["globals"].WpApiUrl}/wp/v2/posts/`, {
					params: {
						per_page: 6,
						_embed: true,
						order: 'desc',
					},
				});

				if (response.data && response.data.length > 0) {
					setLatestPosts(response.data);
				}
			} catch (error) {
				console.error('Error fetching latest posts:', error);
			}
		}
		fetchLatestPosts();
	}, []);

	return (
		<>
			<div className={`col-2-placeholder show`}>
				<div className={`col-2`}>
					<div className={'home-space home-intro-text'}><p>2 Decades of vision...</p></div>
				</div>
			</div>
			<div className={'col-3'}>
				<div className={'image'}>
					{posts.length > 0 ? (
						<Swiper
							modules={[Navigation, Pagination, Autoplay]}
							spaceBetween={0}
							slidesPerView={1}
							navigation
							pagination={{ clickable: true }}
							autoplay={{ delay: 3000, disableOnInteraction: false }}
							loop={true}
						>
							{/* Create a slide for each post */}
							{posts.map((post) => (
								<SwiperSlide key={post.id}>
									<div>
										{post._embedded?.['wp:featuredmedia']?.[0]?.source_url && (
											<Link
												to={`/${defaultCategorySlug}/${post.slug}`}
												onClick={async (event) => {
													event.preventDefault();

													// Fetch category details and store them in sessionStorage
													const slideshowCategory = post.categories?.find(
														(id) => id === slideshowCategoryId
													); // Prioritize slideshowCategory

													const categoryId = slideshowCategory || post.categories?.[0]; // Fallback to first ID

													if (categoryId) {
														const clickedCategory = await fetchCategoryDetails(categoryId);
														if (clickedCategory) {
															sessionStorage.setItem(
																"clickedCategory",
																JSON.stringify(clickedCategory)
															);
														} else {
															console.error("Failed to fetch category details.");
														}
													} else {
														console.error("No category ID found for the post.");
													}

													// Use navigate after a delay
													setTimeout(() => {
														navigate(`/${defaultCategorySlug}/${post.slug}`); // Programmatic navigation
													}, 100);
												}}
												rel="noopener noreferrer"
											>
												<img
													src={post._embedded["wp:featuredmedia"][0].source_url}
													alt={post.title.rendered}
													style={{ marginBottom: "0", cursor: "pointer" }}
													title={post.title.rendered}
												/>
											</Link>
										)}
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					) : (
						<div className="loading">
							<Loading />
						</div>
					)}
				</div>

				{/* Latest 6 Posts Section */}
				<div className={'latest-posts-wrap'}>
					<h2>Latest</h2>
					<div className="latest-posts">
						{latestPosts.length > 0 ? (
							latestPosts.map((post) => (
								<div className="post-item" key={post.id}>
									{post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.medium?.source_url && (
										<Link
											style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url})` }}
											to={`/${defaultCategorySlug}/${post.slug}`}
											title={post.title.rendered}
											onClick={async (event) => {
												event.preventDefault();

												sessionStorage.setItem(
													"clickedCategory",
													JSON.stringify({ id: 149, name: 'Photoblog', slug: 'photoblog' })
												);

												// Use navigate after a delay
												setTimeout(() => {
													navigate(`/${defaultCategorySlug}/${post.slug}`); // Programmatic navigation
												}, 100);
											}}
										>
											<img
												src={post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url}
												alt={post.title.rendered}
												style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
												title={post.title.rendered}
											/>
											<h3>{post.title.rendered}</h3>
										</Link>
									)}
								</div>
							))
						) : (
							<div className="loading">
								<Loading />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;