import React from 'react';
import './loading.css';

const Loading = () => {
	return (
		<>
			<span className='loader'></span>
		</>
	);
}

export default Loading;