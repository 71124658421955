import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import './SearchResults.css';
import { toggleSidebar } from "../Utilities/ToggleSidebar";

const API_URL_OPTIONS = `${global.config["globals"].WpApiUrl}/wp/v2/theme-options/get`;

// Helper function to get featured media
const getFeaturedMedia = (media) => {
	return (
		media?.media_details?.sizes?.thumbnail?.source_url ||
		media?.source_url ||
		null
	);
};

const SearchResults = ({ searchQuery, searchResults, closeSidebar }) => {
	if (!searchResults.length) {
		return <p>No results found for "{searchQuery}".</p>;
	}
	//console.log(searchResults);

	const handleClick = async () => {

		try {
			const { data } = await axios.get(API_URL_OPTIONS);
			const defaultCategory = data?.default_category;

			// Store default category in sessionStorage
			if (defaultCategory) {
				const defaultCategoryObject = {
					id: defaultCategory.id,
					name: defaultCategory.name,
					slug: defaultCategory.slug,
				};
				sessionStorage.setItem('clickedCategory', JSON.stringify(defaultCategoryObject));
			}

			toggleSidebar();


		} catch (error) {
			console.error("Error fetching default category:", error);
		}
	};

	return (
		<div className="search-results">
			{searchResults.map((result, index) => {
				const featuredImage = getFeaturedMedia(result._embedded?.['wp:featuredmedia']?.[0]);

				return (
					<div key={result.id} className="search-result-item">
						<Link
							to={{
								pathname: `/photoblog/${result.slug}`,
							}}
							state={{
								searchResults, // Pass full search results array
								currentIndex: index, // Pass current index
								searchQuery, // Pass the searched words
								totalResults: searchResults.length, // Pass the number of results
							}}

							onClick={handleClick}

						>

						{featuredImage && (
								<img
									src={featuredImage}
									alt={result.title?.rendered || "Featured"}
									className="featured-image"
									title={result.title?.rendered}
								/>
							)}
						</Link>
					</div>
				);
			})}
		</div>
	);
};

export default SearchResults;