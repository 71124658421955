const LOCAL_DEV_HOSTS = ['http://localhost:3000', 'http://192.168.1.218:3000'];
const PRODUCTION_API_BASE = 'https://www.deceptivemedia.co.uk/wp-json';

// this is the url to be replaced from the api when developing site
const DEVELOPMENT_URL_REPLACE = 'https://www.deceptivemedia.co.uk';

function getApiLocation(type) {
	const currentOrigin = window.location.origin;
	const apiBase = LOCAL_DEV_HOSTS.includes(currentOrigin) ? PRODUCTION_API_BASE : `${currentOrigin}/wp-json`;

	// Return the appropriate value based on the type
	return type === 'api' ? apiBase : undefined;
}

module.exports = global.config = {
	globals: {
		WpApiUrl: getApiLocation('api'),
		postsPerPage: 16,
		developmentURLReplace: DEVELOPMENT_URL_REPLACE,
	}
};
