import React, { useEffect } from 'react';

function NotFound() {
	useEffect(() => {
		const timer = setTimeout(() => {
			const navItems = document.querySelectorAll('nav .current, nav .open');
			navItems.forEach(item => {
				item.classList.remove('current', 'open');
			});
		}, 250); // 1 second delay

		return () => clearTimeout(timer); // Cleanup the timer in case the component unmounts
	}, []);

	return (
		<>
			<div className={`col-2-placeholder show`}>
				<div className={`col-2`}>
					<div className={'home-space home-intro-text'}>
						<p>404 not found</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default NotFound;
