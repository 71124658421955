import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SearchBar.css";
import SearchResults from "../SearchResults";

// The API URL for fetching search data
const API_BASE_URL = global.config["globals"].WpApiUrl;
const WP_API_SEARCH = `${API_BASE_URL}/wp/v2/posts`;

const SearchBar = () => {
	const [searchQuery, setSearchQuery] = useState(""); // State for storing search input
	const [searchResults, setSearchResults] = useState([]); // State for storing search results
	const [isLoading, setIsLoading] = useState(false);
	const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

	// Function to handle input change
	const handleInputChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const closeSidebar = () => {
		console.log("Sidebar closed");  // Placeholder action
		// Add sidebar closing logic here
	};


	// Debounce effect: Update debouncedQuery after a delay
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedQuery(searchQuery);
		}, 1000); // Adjust the delay (500ms in this case) as needed

		return () => {
			clearTimeout(handler); // Clear the timeout on cleanup to avoid unnecessary API calls
		};
	}, [searchQuery]);

	// Fetch search results when debouncedQuery changes
	useEffect(() => {
		const fetchResults = async () => {
			if (debouncedQuery.trim() === "") {
				setSearchResults([]);
				setIsLoading(false);
				return;
			}

			setIsLoading(true); // Start loading

			try {
				let allPosts = [];
				let page = 1;
				let totalPages = 1;

				do {
					const postResponse = await axios.get(
						`${WP_API_SEARCH}?search=${debouncedQuery}&_embed&per_page=100&page=${page}`
					);

					allPosts = [...allPosts, ...postResponse.data];
					totalPages = parseInt(postResponse.headers["x-wp-totalpages"], 10);
					page++;
				} while (page <= totalPages);

				setSearchResults(allPosts);
			} catch (error) {
				console.error("Error fetching search results:", error);
			} finally {
				setIsLoading(false); // End loading
			}
		};

		fetchResults();
	}, [debouncedQuery]);

	return (
		<div className="search">
			<div className="search-bar">
				<input
					type="text"
					placeholder="Search..."
					value={searchQuery}
					onChange={handleInputChange}
					className="search-input"

				/>
			</div>

			{isLoading ? (
				<div>Loading...</div>
			) : (
				debouncedQuery.trim() && (
					<SearchResults searchResults={searchResults} searchQuery={debouncedQuery} closeSidebar={closeSidebar}
					/>

				)

			)}
		</div>
	);
};

export default SearchBar;