import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate'; // Import react-paginate
import './Category.css';
import Loading from "../../Loading/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import { useSiteInfo } from '../../WPImport/SiteInfoContext';
import DOMPurify from 'dompurify';

const Category = () => {
	// URL and Navigation
	const { slug, childSlug } = useParams();
	const categorySlug = childSlug || slug;
	const navigate = useNavigate();
	const location = useLocation();

	// Category State
	const [categoryData, setCategoryData] = useState({ id: null, name: null, description: null });
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Posts State
	const [posts, setPosts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalPosts, setTotalPosts] = useState(0);
	const [postsLoading, setPostsLoading] = useState(true);
	const [loaded, setLoaded] = useState(new Map());
	const [delays, setDelays] = useState([]);

	// References
	const elementRef2 = useRef(null);
	const elementRef3 = useRef(null);

	const siteInfo = useSiteInfo();

	useEffect(() => {
		const fetchCategoryData = async () => {
			setLoading(true);
			setPostsLoading(true);
			try {
				const categoryResponse = await axios.get(`${global.config["globals"].WpApiUrl}/wp/v2/categories?slug=${categorySlug}`);
				const catID = categoryResponse.data[0];

				if (catID) {
					setCategoryData({
						id: catID.id,
						name: catID.name,
						description: catID.description.split('\n').map(part => `<p>${part}</p>`).join('')
					});

					const urlParams = new URLSearchParams(location.search);
					let pageParam = Number(urlParams.get('page'));
					if (isNaN(pageParam) || pageParam < 1) pageParam = 1;

					setCurrentPage(pageParam);

					const postsResponse = await axios.get(
						`${global.config["globals"].WpApiUrl}/wp/v2/posts?_embed&categories=${catID.id}&page=${pageParam}&per_page=${global.config["globals"].postsPerPage}`
					);

					const total = postsResponse.headers['x-wp-totalpages'];
					const totalPosts = postsResponse.headers['x-wp-total'];
					setTotalPages(parseInt(total));
					setTotalPosts(parseInt(totalPosts));
					setPosts(postsResponse.data);

				} else {
					setError('Category not found');
				}
			} catch (err) {
				setError('Failed to fetch category data');
			} finally {
				setLoading(false);
				setPostsLoading(false);
			}
		};

		fetchCategoryData().catch(console.error);
	}, [categorySlug, location.search, totalPosts]);

	const handlePageClick = (data) => {
		const selectedPage = data.selected + 1;
		setCurrentPage(selectedPage);

		// Update URL parameter
		const urlParams = new URLSearchParams(location.search);
		urlParams.set('page', selectedPage);
		navigate({ search: urlParams.toString() });
	};


	useEffect(() => {
		if (posts.length > 0 && [...loaded.values()].filter(loaded => loaded).length === posts.length) {
			updateHeight();
			window.addEventListener('resize', updateHeight);
			setTimeout(() => {
				document.querySelectorAll(".container").forEach(element => {
					element.style.opacity = "1";
				});
			}, 1000);
		} else if (posts.length > 0 && delays.length === 0) {
			setDelays(posts.map(() => Math.random() * 0.50)); // Random delay between 0 and 0.5 seconds
		}
	}, [loaded, posts.length, delays.length,posts]);

	const updateHeight = () => {
		if (elementRef2.current && elementRef3.current) {
			const col3Height = elementRef3.current.offsetHeight;
			if (window.matchMedia(`(min-width: 1700px)`).matches) {
				elementRef2.current.style.height = `${(col3Height - 140) / 2}px`;
			} else {
				elementRef2.current.style.height = '';
			}
		}
	};

	const handlePostLoad = (url) => {
		setLoaded(new Map(loaded.set(url, true)));
	};

	const updateUrlParams = (page) => {
		const urlParams = new URLSearchParams(location.search);
		urlParams.set('page', page);
		navigate({ search: urlParams.toString() });
	};

	const getPageRange = () => {
		const range = [];
		let start, end;

		if (totalPages <= 5) {
			start = 1;
			end = totalPages;
		} else if (currentPage <= 3) {
			start = 1;
			end = 5;
		} else if (currentPage + 2 >= totalPages) {
			start = totalPages - 4;
			end = totalPages;
		} else {
			start = currentPage - 2;
			end = currentPage + 2;
		}

		for (let i = start; i <= end; i++) {
			range.push(i);
		}

		return range;
	};

	const pageRange = getPageRange();

	if (loading || postsLoading) {
		return (
			<div className="loading">
				<Loading />
			</div>
		);
	}

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<Helmet>
				<title>Category: {categoryData.name} - {siteInfo.name}</title>
			</Helmet>
			<div className="col-2">
				<div className="container">
					<h1>{categoryData.name} [{totalPosts}]</h1>
				</div>
			</div>
			<div className="col-3 category">
				<section id="thumbnails">
					<div className="container">
						<div className="thumbnail-wrap">
							{posts.map((post, index) => {
								const sourceUrl = post["_embedded"]['wp:featuredmedia'][0]["media_details"].sizes["medium"]["source_url"];
								const delay = delays[index] || 0;
								return (
									<Link key={post.id} className="thumbnail" style={{ backgroundImage: `url(${post["_embedded"]["wp:featuredmedia"][0]["media_details"].sizes["average-color"]["source_url"]})` }} to={`/photoblog/${post["slug"]}`} title={post.title["rendered"]}>
										<img
											className={`wp-post-image ${loaded.get(sourceUrl) ? "fade-in" : ""}`}
											width={300}
											height={200}
											alt={post.title["rendered"]}
											src={sourceUrl}
											style={{ animation: `fade-in 0.5s ease-in ${delay * 2}s both` }}
											onLoad={() => handlePostLoad(sourceUrl)}
											onError={(error) => console.log('Failed to load the image: ', error)}
										/>
										<h2>{post.title["rendered"]}</h2>
									</Link>
								);
							})}
						</div>
					</div>
				</section>
				{categoryData.description && DOMPurify.sanitize(categoryData.description).replace(/<[^>]*>?/gm, '').trim() && (
					<div className="description cat-description" dangerouslySetInnerHTML={{ __html: categoryData.description || '' }} />
				)}

			</div>
			<div className="thumbnail-navigation-wrap">
				{totalPages > 1 && (
					<ReactPaginate
						previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
						nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
						breakLabel="..."
						breakClassName="break-me"
						pageCount={totalPages}
						marginPagesDisplayed={1}
						pageRangeDisplayed={3}
						onPageChange={handlePageClick}
						forcePage={currentPage - 1}
						containerClassName="pagination"
						activeClassName="active"
						previousClassName="previous-page"
						nextClassName="next-page"
						disabledClassName="disabled"
					/>
				)}
			</div>


		</>
	);
};

export default Category;