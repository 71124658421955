import React, { useState } from "react";
import "./SideBar.css";
import Footer from "../footer/"
import Menu from "../WPImport/Menu/";
import SearchBar from "../SearchBar/";
//import GetTaxonomy from "../../components/WPImport/GetTaxonomy/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import {toggleSidebar} from "../Utilities/ToggleSidebar";

const SideBar = () => {
	const [activeTab, setActiveTab] = useState("menu"); // State to manage active tab (menu/search/taxonomy)

	const handleClick = async () => {
		toggleSidebar();
	};

	return (
		<>
			<div className={`sidebar`}>
				<div className="toggle-icons">
					{/* Menu Icon */}

						<button
							className={`toggle-icon ${activeTab === "menu" ? "active" : ""}`}
							onClick={() => setActiveTab("menu")}
						>
							<FontAwesomeIcon icon={faBars}/> menu
						</button>


					{/* Search Icon */}

						<button
							className={`toggle-icon ${activeTab === "search" ? "active" : ""}`}
							onClick={() => setActiveTab("search")}
						>
							<FontAwesomeIcon icon={faSearch}/> search
						</button>


					{/* Taxonomy Filter Icon */}

					{/*	<button*/}
					{/*		className={`toggle-icon ${activeTab === "taxonomy" ? "active" : ""}`}*/}
					{/*		onClick={() => setActiveTab("taxonomy")}*/}
					{/*	>*/}
					{/*		<FontAwesomeIcon icon={faFilter}/> filter*/}
					{/*	</button>*/}

				</div>

				{/* Menu Container */}
				<div
					className={`sidebar-container menu-container ${
						activeTab === "menu" ? "visible" : "hidden"
					}`}
				>
					<nav>
						<Menu />
					</nav>
				</div>

				{/* SearchBar Container */}
				<div
					className={`sidebar-container search-container ${
						activeTab === "search" ? "visible" : "hidden"
					}`}
				>
					<SearchBar />
				</div>

				{/* GetTaxonomy Container */}
				{/*<div*/}
				{/*	className={`sidebar-container taxonomy-container ${*/}
				{/*		activeTab === "taxonomy" ? "visible" : "hidden"*/}
				{/*	}`}*/}
				{/*>*/}
				{/*	<nav>*/}
				{/*	<GetTaxonomy />*/}
				{/*	</nav>*/}
				{/*</div>*/}

				{/* Footer Section */}
				<Footer className="footer-sidebar" />
			</div>

			<div
				className={`hamburger`}
				onClick={handleClick}
			>
				<div className="bar"></div>
				<div className="bar"></div>
				<div className="bar"></div>
			</div>
		</>
	);
};

export default SideBar;