export const toggleSidebar = () => {
	// Check if width is less than 1000px
	if (window.innerWidth < 1000) {
		const sidebar = document.querySelector('.sidebar');
		const hamburger = document.querySelector('.hamburger');
		const body = document.body; // Reference to the body element

		if (sidebar && hamburger) {
			// Check if sidebar is currently open
			const isSidebarVisible = sidebar.classList.contains('open');

			// Toggle classes to open/close
			if (isSidebarVisible) {
				sidebar.classList.remove('open');
				hamburger.classList.remove('active');
				body.classList.remove('nav-open'); // Remove nav-open class from body
			} else {
				sidebar.classList.add('open');
				hamburger.classList.add('active');
				body.classList.add('nav-open'); // Add nav-open class to body
			}
		}
	}
};
