import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify'; // Safely render HTML content
import './Page.css';
import Loading from './../../Loading/Loading';

const Page = ({ page }) => {
	const [isLoading, setIsLoading] = useState(true); // Loading state for content
	const [isImageLoading, setIsImageLoading] = useState(true); // Loading state for the image
	const [content, setContent] = useState(null); // Page content

	useEffect(() => {
		// Simulate a loading delay or fetching of content
		const timer = setTimeout(() => {
			setContent(page); // Simulate content loading
			setIsLoading(false); // Stop the content loading state
		}, 1000); // Adjust timing as needed

		return () => clearTimeout(timer); // Cleanup timeout on unmount
	}, [page]);

	const featuredImage =
		content &&
		content._embedded &&
		content._embedded['wp:featuredmedia'] &&
		content._embedded['wp:featuredmedia'][0]?.source_url;

	const handleImageLoad = () => {
		setIsImageLoading(false); // Change image loading state when the image is loaded
	};

	if (isLoading) {
		return (
			<div className="loading">
				<Loading />
			</div>
		);
	}

	return (
		<>
			<div className={`col-2-placeholder show`}>
				<div className={`col-2`}>
					<div className={'home-space home-intro-text'}>
						<p>{content.title.rendered}</p>
					</div>
				</div>
			</div>
			<div className={'col-3'}>
				{featuredImage && (
					<div className={'image'}>
						{isImageLoading && (
							<div className="loading">
								<Loading />
							</div>
						)}
						<img
							src={featuredImage}
							alt={content.title.rendered}
							onLoad={handleImageLoad} // Trigger when the image has loaded
							style={{ display: isImageLoading ? 'none' : 'block' }} // Hide image until loaded
						/>
					</div>
				)}
				<div
					className={'intro-text'}
					// Sanitize the WordPress page content before rendering
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.content.rendered) }}
				></div>
			</div>
		</>
	);
};

export default Page;