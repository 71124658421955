import React, { useEffect } from 'react';
import Logo from "./../../Logo";
import { Link } from "react-router-dom";
import "./SiteInfo.css";
import { useSiteInfo } from '../SiteInfoContext';
const SiteInfo = () => {
	const siteInfo = useSiteInfo();

	const FaviconUpdater = ({ iconUrl }) => {
		useEffect(() => {
			let link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}
			link.href = iconUrl;
		}, [iconUrl]);

		return null;
	};

	return (
		<>
			<div className={'site-info'}>
				<Link to="/" aria-label="Go to Home Page"><Logo/></Link>
				<FaviconUpdater iconUrl={siteInfo.iconUrl} />
				<div>
					<h1>{siteInfo.name}</h1>
					<p>{siteInfo.description}</p>
				</div>
			</div>
		</>
	);
};

export default SiteInfo;