import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import Loading from './../../Loading/Loading';
import { Helmet } from 'react-helmet';
import './post.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTag } from '@fortawesome/free-solid-svg-icons';
import '../../../config';
import { useSiteInfo } from '../SiteInfoContext';

const API_URL = `${global.config["globals"].WpApiUrl}/wp/v2/posts`;
const API_URL_OPTIONS = `${global.config["globals"].WpApiUrl}/wp/v2/theme-options/get`;


const SinglePost = () => {

	const location = useLocation();
	const { searchQuery, totalResults, currentIndex, searchResults } = location.state || {};

	const { slug } = useParams();
	const [post, setPost] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [nextPost, setNextPost] = useState(null);
	const [prevPost, setPrevPost] = useState(null);
	const [categoryOrTagName, setCategoryOrTagName] = useState('');
	const [categoryOrTagSlug, setCategoryOrTagSlug] = useState('');
	const [isCategory, setIsCategory] = useState(false);
	const [pageNum, setPageNum] = useState(null);
	const siteInfo = useSiteInfo();



	const resetState = () => {
		setPost(null);
		setNextPost(null);
		setPrevPost(null);
		setCategoryOrTagName('');
		setCategoryOrTagSlug('');
		setIsCategory(false);
		setPageNum(null);
		setImageLoaded(false);
		setLoading(true);
		setError(null);
	};

	const fetchPostPageNumber = async (id, type, postId) => {
		try {
			const perPage = global.config["globals"].postsPerPage;
			const totalResponse = await axios.get(`${API_URL}?${type}=${id}&per_page=1`);
			const totalPosts = parseInt(totalResponse.headers['x-wp-total'], 10);
			const response = await axios.get(`${API_URL}?${type}=${id}&per_page=${totalPosts}&_fields=id`);
			const postList = response.data;
			const postIndex = postList.findIndex(post => post.id === postId);
			const postPageNum = Math.ceil((postIndex + 1) / perPage);
			setPageNum(postPageNum);
		} catch (error) {
			console.error('Error fetching post page number:', error);
		}
	};

	const fetchAdjacentPosts = async (postId, postDate) => {
		try {
			// Check if clickedCategory or clickedTag exist in sessionStorage
			let clickedCategoryString = sessionStorage.getItem('clickedCategory');
			let clickedTagString = sessionStorage.getItem('clickedTag');

			// Fetch default category if needed
			if (!clickedCategoryString && !clickedTagString) {
				const themeOptionsResponse = await axios.get(API_URL_OPTIONS);

				// Retrieve the default_category id from the theme options API response
				const defaultCategory = themeOptionsResponse?.data?.default_category.id;
				const defaultCategoryName = themeOptionsResponse?.data?.default_category.name;
				const defaultCategorySlug = themeOptionsResponse?.data?.default_category.slug;
				if (defaultCategory) {
					const defaultCategoryObject = {
						id: defaultCategory,
						name: defaultCategoryName, // Replace it with appropriate logic if name is available
						slug: defaultCategorySlug, // Replace it if slug is available in theme options
					};
					// Store the default category in sessionStorage
					sessionStorage.setItem('clickedCategory', JSON.stringify(defaultCategoryObject));
					clickedCategoryString = JSON.stringify(defaultCategoryObject);
				}
			}

			// Wait for sessionStorage values if still missing
			while (!clickedCategoryString && !clickedTagString) {
				await new Promise(resolve => setTimeout(resolve, 300));
				clickedCategoryString = sessionStorage.getItem('clickedCategory');
				clickedTagString = sessionStorage.getItem('clickedTag');
			}

			// Parse sessionStorage strings
			const clickedCategory = clickedCategoryString && JSON.parse(clickedCategoryString);
			const clickedTag = clickedTagString && JSON.parse(clickedTagString);
			let prevPostResponse, nextPostResponse;

			// Process clickedCategory or clickedTag accordingly
			if (clickedCategory) {
				setCategoryOrTagName(clickedCategory.name);
				setCategoryOrTagSlug(clickedCategory.slug);
				setIsCategory(true);
				[prevPostResponse, nextPostResponse] = await axios.all([
					axios.get(`${API_URL}?categories=${clickedCategory.id}&before=${postDate}&per_page=1&order=desc`),
					axios.get(`${API_URL}?categories=${clickedCategory.id}&after=${postDate}&per_page=1&order=asc`),
				]);
				await fetchPostPageNumber(clickedCategory.id, 'categories', postId); // Efficient page number calculation
			} else if (clickedTag) {
				setCategoryOrTagName(clickedTag.name);
				setCategoryOrTagSlug(clickedTag.slug);
				setIsCategory(false);
				[prevPostResponse, nextPostResponse] = await axios.all([
					axios.get(`${API_URL}?tags=${clickedTag.id}&before=${postDate}&per_page=1&order=desc`),
					axios.get(`${API_URL}?tags=${clickedTag.id}&after=${postDate}&per_page=1&order=asc`),
				]);
				await fetchPostPageNumber(clickedTag.id, 'tags', postId); // Efficient page number calculation
			}

			// Set adjacent posts
			if (prevPostResponse?.data.length > 0 && prevPostResponse.data[0].slug !== slug) {
				setPrevPost(prevPostResponse.data[0]);
			}
			if (nextPostResponse?.data.length > 0 && nextPostResponse.data[0].slug !== slug) {
				setNextPost(nextPostResponse.data[0]);
			}
		} catch (error) {
			console.error('Error fetching adjacent posts:', error);
		}
	};


	useEffect(() => {






		resetState();

		const getPost = async () => {
			try {
				const response = await axios.get(`${API_URL}?slug=${slug}&_embed`);
				if (response.data.length > 0) {
					const fetchedPost = response.data[0];
					setPost(fetchedPost);

					if (searchResults?.length > 0) {
						// Using searchResults for next and previous
						calculateAdjacentPostsFromSearchResults(fetchedPost);
					} else {
						// Fallback to the current logic
						await fetchAdjacentPosts(fetchedPost.id, fetchedPost.date);
					}
				}
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		};

		getPost();
	}, [slug]);

	const calculateAdjacentPostsFromSearchResults = (currentPost) => {
		// Guard for empty or invalid currentIndex or searchResults
		if (!searchResults || typeof currentIndex !== "number" || currentIndex < 0 || currentIndex >= searchResults.length) {
			return;
		}

		// Calculate previous and next posts based on currentIndex
		const prevIndex = currentIndex - 1; // Previous comes before current
		const nextIndex = currentIndex + 1; // Next comes after current

		// Use bounds checking to verify and set adjacent posts
		setPrevPost(prevIndex >= 0 ? searchResults[prevIndex] : null); // Set null if out of bounds
		setNextPost(nextIndex < searchResults.length ? searchResults[nextIndex] : null); // Set null if out of bounds
	};



	const removeCurrentFromSidebar = () => {
		const sidebarItems = document.querySelectorAll('.sidebar nav .current, .sidebar nav .open');
		sidebarItems.forEach(item => {
			item.classList.remove('current');
			//item.classList.replace('open', 'closed');
		});
	};

	if (loading) {
		return (
			<div className="col-2 image">
				<div className="loading level-10">
					<Loading />
				</div>
			</div>
		);
	}

	if (error) {
		console.error('Error fetching post:', error);
		return <p>There was an error loading the post.</p>;
	}

	if (post) {
		const featuredImage = post["_embedded"]?.['wp:featuredmedia']?.[0];
		const imgSrc = featuredImage ? featuredImage['source_url'] : null;
		const imgSizes = featuredImage ? featuredImage['media_details']['sizes'] : {};
		const imgSrcSet = imgSizes ? Object.values(imgSizes).map(size => `${size['source_url']} ${size['width']}w`).join(", ") : null;
		const tags = post["_embedded"]?.['wp:term']?.find(t => t.some(term => term.taxonomy === 'post_tag')) || [];
		const categories = post["_embedded"]?.['wp:term']?.find(t => t.some(term => term.taxonomy === 'category')) || [];

		//const areAdjacentPostsLoaded = pageNum !== null;
		const formattedDate = new Intl.DateTimeFormat('en-GB', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		}).format(new Date(post.date));

		return (
			<>
				<Helmet>
					<title>{post.title["rendered"]} - {siteInfo.name}</title>
				</Helmet>

					<div className={`col-2`}>
						<div className={'container'}>
						<div className={'breadCrumbs'}>
                            <span className={'text-transform-capitalize'}>
                                {categoryOrTagName && (
	                                <Link
		                                to={isCategory ? `/category/${categoryOrTagSlug}?page=${pageNum}` : `/tag/${categoryOrTagSlug}?page=${pageNum}`}>
		                                {isCategory ? (
			                                <>{categoryOrTagName}{pageNum && (
				                                <span className="post-page-number"> [p{pageNum}]</span>
			                                )}</>
		                                ) : (
			                                <>
				                                <FontAwesomeIcon icon={faTag} /> {categoryOrTagName}{pageNum && (
				                                <span className="post-page-number"> (p{pageNum})</span>
			                                )}
			                                </>
		                                )}
	                                </Link>
                                )}
	                            {categoryOrTagName && ' / '}
                            </span>
							{searchResults && searchResults.length > 0 && (
								<span>
									Search Results for '{searchQuery}' [{totalResults}]:
								</span>
							)}

						{post.title["rendered"]}
						</div>
						</div>
					</div>

				<div className={`col-3`}>
					<div className="image" style={ {backgroundImage: `url(${ featuredImage ? post["_embedded"]["wp:featuredmedia"][0]["media_details"].sizes["average-color"]["source_url"] : '' })`} }>
						{ imgSrc &&
							<img
								src={ imgSrc }
								height={ 200 }
								width={ 300 }
								srcSet={ imgSrcSet }
								sizes=""
								alt={ post.title["rendered"] }
								className={ `main-image fade-in ${ imageLoaded ? 'loaded' : '' }` }
								onLoad={ () => setImageLoaded(true) }
							/>
						}
						<div className={ `loading level-4` } style={ {opacity: imageLoaded ? 0 : 1} }>
							<Loading/>
						</div>
					</div>
					<div className={ 'description' }>
						<h1>{ post.title["rendered"] }</h1>
						<span className="post-date"> {formattedDate}</span>
						<div dangerouslySetInnerHTML={ {__html: post.content["rendered"]} }/>
					</div>

					{/* Category Section */}
					{categories.length > 0 && (
						<div className="categories-wrapper">
							<span className="category-label">Categories:</span>
							<ul className="categories">
								{categories.map(category => (
									<li key={category.id}>
										<Link
											to={`/category/${category.slug}`}
											title={category.name}
											onClick={() => {
												const clickedCategoryString = sessionStorage.getItem('clickedCategory');
												const clickedCategory = clickedCategoryString ? JSON.parse(clickedCategoryString) : null;

												// Check if clickedCategory matches the current category
												if (clickedCategory && clickedCategory.slug === category.slug) {
													// Navigate back using JavaScript
													window.history.back();
												} else {
													// Update sessionStorage with the new clickedCategory object
													sessionStorage.setItem(
														'clickedCategory',
														JSON.stringify({ id: category.id, name: category.name, slug: category.slug })
													);
													sessionStorage.removeItem('clickedTag');
													sessionStorage.removeItem('openCategories');
													removeCurrentFromSidebar();
												}
											}}
										>
											{category.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}


					{/* Tags Section */}
					{tags.length > 0 && (
						<div className="tags-wrapper">
							<span className="tags-label">Tags:</span>
							<ul className="tags">
								{tags.map(tag => (
									<li key={tag.id}>
										<Link
											to={`/tag/${tag.slug}`}
											title={tag.name}
											onClick={() => {
												//sessionStorage.setItem('activeUrl', `/tag/${tag.slug}`);

												sessionStorage.setItem(
													'clickedTag',
													JSON.stringify({ id: tag.id, name: tag.name, slug: tag.slug })
												);
												sessionStorage.removeItem('clickedCategory');
												sessionStorage.removeItem('openCategories');
												removeCurrentFromSidebar();
											}}
										>
											{tag.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>

				{/*{ areAdjacentPostsLoaded && (*/}
				{searchResults && searchResults.length > 0 ? (

						<div className="navigation-arrows">
					{/* Previous Post */}
					<Link
						to={
							searchResults && searchResults.length > 0
								? currentIndex > 0
									? `/photoblog/${searchResults[currentIndex - 1]?.slug}`
									: '#'
								: prevPost
									? `/photoblog/${prevPost.slug}`
									: '#'
						}
						className={`prev-arrow ${
							(searchResults && currentIndex > 0) || (prevPost && prevPost.slug !== slug) ? '' : 'disabled'
						}`} // Disable if no valid previous post
						onClick={(e) => {
							if (
								(searchResults && currentIndex <= 0) ||
								(!searchResults && (!prevPost || prevPost.slug === slug))
							) {
								e.preventDefault();
							}
						}}
						state={
							searchResults
								? {
									searchResults: searchResults,
									currentIndex: currentIndex - 1,
									searchQuery: searchQuery,
									totalResults: totalResults,
								}
								: undefined
						}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
					</Link>

					{/* Next Post */}
					<Link
						to={
							searchResults && searchResults.length > 0
								? currentIndex < searchResults.length - 1
									? `/photoblog/${searchResults[currentIndex + 1]?.slug}`
									: '#'
								: nextPost
									? `/photoblog/${nextPost.slug}`
									: '#'
						}
						className={`next-arrow ${
							(searchResults && currentIndex < searchResults.length - 1) || (nextPost && nextPost.slug !== slug)
								? ''
								: 'disabled'
						}`} // Disable if no valid next post
						onClick={(e) => {
							if (
								(searchResults && currentIndex >= searchResults.length - 1) ||
								(!searchResults && (!nextPost || nextPost.slug === slug))
							) {
								e.preventDefault();
							}
						}}
						state={
							searchResults
								? {
									searchResults: searchResults,
									currentIndex: currentIndex + 1,
									searchQuery: searchQuery,
									totalResults: totalResults,
								}
								: undefined
						}
					>
						<FontAwesomeIcon icon={faChevronRight} />
					</Link>
				</div>
				) : (
					<div className="navigation-arrows">
						{/* Previous Post */}


						{/* Next Post */}
						<Link
							to={
								searchResults && searchResults.length > 0
									? currentIndex < searchResults.length - 1
										? `/photoblog/${searchResults[currentIndex + 1]?.slug}`
										: '#'
									: nextPost
										? `/photoblog/${nextPost.slug}`
										: '#'
							}
							className={`next-arrow ${
								(searchResults && currentIndex < searchResults.length - 1) || (nextPost && nextPost.slug !== slug)
									? ''
									: 'disabled'
							}`} // Disable if no valid next post
							onClick={(e) => {
								if (
									(searchResults && currentIndex >= searchResults.length - 1) ||
									(!searchResults && (!nextPost || nextPost.slug === slug))
								) {
									e.preventDefault();
								}
							}}
							state={
								searchResults
									? {
										searchResults: searchResults,
										currentIndex: currentIndex + 1,
										searchQuery: searchQuery,
										totalResults: totalResults,
									}
									: undefined
							}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</Link>
						<Link
							to={
								searchResults && searchResults.length > 0
									? currentIndex > 0
										? `/photoblog/${searchResults[currentIndex - 1]?.slug}`
										: '#'
									: prevPost
										? `/photoblog/${prevPost.slug}`
										: '#'
							}
							className={`prev-arrow ${
								(searchResults && currentIndex > 0) || (prevPost && prevPost.slug !== slug) ? '' : 'disabled'
							}`} // Disable if no valid previous post
							onClick={(e) => {
								if (
									(searchResults && currentIndex <= 0) ||
									(!searchResults && (!prevPost || prevPost.slug === slug))
								) {
									e.preventDefault();
								}
							}}
							state={
								searchResults
									? {
										searchResults: searchResults,
										currentIndex: currentIndex - 1,
										searchQuery: searchQuery,
										totalResults: totalResults,
									}
									: undefined
							}
						>
							<FontAwesomeIcon icon={faChevronRight} />
						</Link>
					</div>

				)}

				{/*)}*/}
			</>
		);
	}
	return null;
};

export default SinglePost;