import React, { useEffect, useState } from "react";
import './ThemeSwitcher.css';


const ThemeSwitcher = () => {

	// Theme state: "light", "dark", or "system"
	const [theme, setTheme] = useState(() => {
		const savedTheme = localStorage.getItem("theme");
		if (savedTheme) {
			return savedTheme;
		}
		// Check the system's preferred color scheme
		const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
		return prefersDarkMode ? "dark" : "light";
	});


	// Function to apply the theme
	const applyTheme = (selectedTheme) => {
		if (selectedTheme === "system") {
			document.documentElement.removeAttribute("data-theme");
		} else {
			document.documentElement.setAttribute("data-theme", selectedTheme);
		}

		setTheme(selectedTheme);
		localStorage.setItem("theme", selectedTheme);
	};

	// Function to toggle between light and dark themes
	const toggleTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		applyTheme(newTheme);
	};

	// Effect to apply the theme on initial load
	useEffect(() => {
		if (theme === "system") {
			document.documentElement.removeAttribute("data-theme");
		} else {
			document.documentElement.setAttribute("data-theme", theme);
		}
	}, [theme]);



	return (
		<>
			<div className={'theme-toggle'}>
				<span><span className={'theme-text'}>Theme</span> {theme === "light" ? "" : ""}</span>
				<button
					id="toggle-theme-button"
					onClick={toggleTheme}
					className={theme === "light" || theme === "dark" ? "active" : ""}
					title={'toggle theme'}
				>
					<div
						className={theme === "light" ? "dark-solid" : "light-solid"}
					></div>
				</button>

				{/* System Default Button */}
				<button
					id="system-default-button"
					onClick={() => applyTheme("system")}
					className={theme === "system" ? "active" : ""}
					title={'reset to system default'}
				>
					X
				</button>
			</div>
		</>
	);
};

export default ThemeSwitcher;
