import React from "react";
import './SocialLinks.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const SocialLinks = () => {
	return (
		<>

			<div className="social-media-icons">
				<a
					href="https://instagram.com/deceptivemedia"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Follow us on Instagram"
					title={'instagram'}
				>
					<FontAwesomeIcon icon={faInstagram} />
				</a>
				<a
					href="https://facebook.com/DeceptiveMedia"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Follow us on Facebook"
					title={'facebook'}
				>
					<FontAwesomeIcon icon={faFacebook} />
				</a>
				<a
					href="https://x.com/Deceptive_Media"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Follow us on X"
					title={'X'}
				>
					<FontAwesomeIcon icon={faXTwitter} />
				</a>
			</div>

		</>
	);
};

export default SocialLinks;
