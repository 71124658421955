import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menu.css';
import '../../../config';
import { toggleSidebar } from "../../Utilities/ToggleSidebar";
const API_URL = `${global.config["globals"].WpApiUrl}/wp/v2/menus`;

const Menu = ({ onLinkClick }) => {
	const [menuItems, setMenuItems] = useState([]);
	const [activeUrl, setActiveUrl] = useState('');
	const location = useLocation();

	useEffect(() => {
		// Set the active URL state when location changes
		setActiveUrl(location.pathname);

		// Clear session storage if navigating to the homepage
		if (location.pathname === "/") {
			sessionStorage.removeItem("clickedCategory");
			sessionStorage.removeItem("clickedTag");
		}
	}, [location]);

	useEffect(() => {
		// Fetch the WordPress menu and prepare its structure
		const fetchMenu = async () => {
			try {
				const res = await fetch(API_URL);
				if (!res.ok) {
					throw new Error(`HTTP error! status: ${res.status}`);
				}
				const data = await res.json();
				const currentHost = window.location.origin;
				const updatedMenuItems = data.map((item) => ({
					...item,
					url: item.url.replace(global.config["globals"].developmentURLReplace, currentHost),
				}));
				setMenuItems(updatedMenuItems);
			} catch (error) {
				console.error("Failed to fetch menu:", error);
			}
		};
		fetchMenu();
	}, []);

	const buildHierarchy = (items) => {
		const map = {};
		const roots = [];
		items.forEach((item) => {
			map[item.db_id] = { ...item, children: [] };
		});
		items.forEach((item) => {
			if (item.menu_item_parent !== "0") {
				const parent = map[item.menu_item_parent];
				if (parent) {
					parent.children.push(map[item.db_id]);
				}
			} else {
				roots.push(map[item.db_id]);
			}
		});
		return roots;
	};

	const extractSlugFromUrl = (url) => {
		try {
			if (!url || typeof url !== 'string') return ''; // Early return if the URL is invalid
			const absoluteUrl = url.startsWith('http') ? url : `${window.location.origin}${url}`;
			const pathname = new URL(absoluteUrl).pathname;
			const parts = pathname.split('/').filter(Boolean);
			return parts[parts.length - 1] || ''; // Return the last segment or an empty string
		} catch (error) {
			console.error('Invalid URL:', url, error);
			return ''; // Handle invalid URLs gracefully
		}
	};

	const isActiveMenuItem = (item) => {
		const currentSlug = extractSlugFromUrl(activeUrl)?.toLowerCase() || ''; // Safe fallback for activeUrl
		const itemSlug = extractSlugFromUrl(item.url)?.toLowerCase() || ''; // Safe fallback for item url

		// Match on the base slug for both the active URL and the menu item
		if (currentSlug === itemSlug) {
			return true;
		}


		// Check session storage for clicked category
		const clickedCategory = sessionStorage.getItem('clickedCategory');
		//console.log("Clicked Category:", clickedCategory);
		if (clickedCategory) {
			const category = JSON.parse(clickedCategory);
			if ((category.slug || '').toLowerCase() === itemSlug) {
				return true;
			}
		}

		return false; // If no match, return false
	};

	const handleClick = (item) => {
		if (!item) return;
		onLinkClick && onLinkClick();
		toggleSidebar();
		const clickedSlug = extractSlugFromUrl(item.url)?.toLowerCase() || '';
		const currentSlug = extractSlugFromUrl(activeUrl)?.toLowerCase() || '';
		if (clickedSlug === currentSlug) {
			return; // Prevent unnecessary state updates
		}

		setActiveUrl(item.url);

		if (item.object === "category") {
			const clickedCategory = {
				id: item.object_id,
				name: item.title,
				slug: item.slug || extractSlugFromUrl(item.url),
				link: item.url,
			};
			sessionStorage.setItem('clickedCategory', JSON.stringify(clickedCategory));
		} else {
			sessionStorage.removeItem("clickedCategory");
			sessionStorage.removeItem("clickedTag");
		}
	};
	
	const renderMenu = (items) => {
		return items.map((item) => {
			const hasChildren = item.children && item.children.length > 0;

			return (
				<ul key={item.db_id}>
					<li className={isActiveMenuItem(item) ? 'current' : ''}>
						{hasChildren ? (
							<span title={item.title} className="parent-item">
								{item.title}
							</span>
						) : (
							<Link
								title={item.title}
								to={item.url}
								onClick={() => handleClick(item)}
							>
								{item.title}
							</Link>
						)}
						{hasChildren && (
							<ul className="submenu">{renderMenu(item.children)}</ul>
						)}
					</li>
				</ul>
			);
		});
	};

	const hierarchicalMenu = buildHierarchy(menuItems);

	return <>{renderMenu(hierarchicalMenu)}</>;
};

export default Menu;