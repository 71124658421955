// noinspection JSValidateTypes

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import SideBar from "./components/SideBar";
import Category from './components/pages/Category';
import Tag from './components/pages/Tag';
import Post from './components/WPImport/Post';
import Home from './components/pages/home';
import SiteInfo from "./components/WPImport/SiteInfo";
import SiteInfoProvider from './components/WPImport/SiteInfoContext.js';
import NotFound from './components/pages/NotFound';
import Page from './components/pages/Page';
import Footer from "./components/footer";

// Constants for external configuration
const API_BASE_URL = global.config["globals"].WpApiUrl;
const API_URL_OPTIONS = `${API_BASE_URL}/wp/v2/theme-options/get`;
const API_URL_PAGES = `${API_BASE_URL}/wp/v2/pages`;
const GA_MEASUREMENT_ID = 'G-8N7H4DXFH2';

function App() {
	const location = useLocation();
	const [pages, setPages] = useState([]);
	const [photoblogSlug, setPhotoblogSlug] = useState("photoblog");

	// Helper function for data fetching
	const fetchData = async (url, setter, defaultValue = []) => {
		try {
			const response = await axios.get(url);
			setter(response.data || defaultValue);
		} catch (error) {
			console.error(`Error fetching data from ${url}:`, error);
		}
	};

	// Handle Google Analytics initialization and tracking
	useEffect(() => {
		ReactGA.initialize(GA_MEASUREMENT_ID);
		ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
	}, [location]);

	// Fetch the photoblog slug dynamically
	useEffect(() => {
		fetchData(API_URL_OPTIONS, (data) => data.photoblogSlug && setPhotoblogSlug(data.photoblogSlug));
	}, []);


	// Fetch WordPress pages
	useEffect(() => {
		fetchData(`${API_URL_PAGES}?_embed`, setPages);
	}, []);



	// calculate prev/next thumbnail nav position
	useEffect(() => {
		const updateElementsPosition = () => {
			const contentWrap = document.querySelector('.app-wrap');
			const navigationArrows = document.querySelector('.navigation-arrows');
			const thumbnailNavigationWrap = document.querySelector('.thumbnail-navigation-wrap');

			if (contentWrap) {
				const rect1 = contentWrap.getBoundingClientRect();
				const width1 = rect1.width;

				if (navigationArrows) {
					const rect2 = navigationArrows.getBoundingClientRect();
					const width2 = rect2.width;
					const offsetNavArrows = (window.innerWidth - width1) / 2 - 20; // Arbitrary offset for navigation arrows
					const leftValueNavArrows = width1 + offsetNavArrows - width2; // Calculate left value for navigation arrows
					navigationArrows.style.left = `${leftValueNavArrows}px`;
					navigationArrows.style.visibility = `visible`;
				}

				if (thumbnailNavigationWrap) {
					const rect3 = thumbnailNavigationWrap.getBoundingClientRect();
					const width3 = rect3.width;
					const offsetThumbNav = (window.innerWidth - width1) / 2; // Arbitrary offset for thumbnail navigation
					const leftValueThumbNav = width1 + offsetThumbNav - width3; // Calculate left value for thumbnail navigation
					thumbnailNavigationWrap.style.left = `${leftValueThumbNav}px`;
					thumbnailNavigationWrap.style.visibility = `visible`;
				}
			}
		};

		const waitForElements = () => {
			const intervalId = setInterval(() => {
				const navigationArrows = document.querySelector('.navigation-arrows');
				const thumbnailNavigationWrap = document.querySelector('.thumbnail-navigation-wrap');

				if (navigationArrows || thumbnailNavigationWrap) {
					clearInterval(intervalId); // Stop checking once at least one element is found
					updateElementsPosition(); // Call the function now that the element is available
					window.addEventListener('resize', updateElementsPosition); // Add resize logic
				}
			}, 100); // Check every 100 ms
		};

		// Trigger on component load and route changes
		waitForElements();

		return () => {
			window.removeEventListener('resize', updateElementsPosition); // Cleanup listener
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [useLocation()]); // Re-run whenever location changes


	// Add 'loggedIn' class to app-wrap if #wpadminbar exists
	const observeDomChanges = () => {
		const observer = new MutationObserver(() => {
			const wpAdminBar = document.querySelector('#wpadminbar');
			if (wpAdminBar) {
				document.querySelector('.app-wrap')?.classList.add('loggedIn');
				observer.disconnect();
			}
		});
		observer.observe(document.body, { childList: true, subtree: true });
		return () => observer.disconnect();
	};

	useEffect(observeDomChanges, []);

	return (
		<SiteInfoProvider>
			<div className="app-wrap">
				<div className="col-1">
					<SiteInfo />
					<SideBar />
				</div>
				<div className="content-wrap">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path={`/${photoblogSlug}/:slug`} element={<Post />} />
						<Route path="/category/:slug" element={<Category />} />
						<Route path="/category/:parentSlug/:childSlug" element={<Category />} />
						<Route path="/tag/:slug" element={<Tag />} />
						{pages.map((page) => (
							<Route
								key={page.id}
								path={`/${page.slug}`}
								element={<Page page={page} />}
							/>
						))}
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
				<Footer className="footer-mobile" />
			</div>
		</SiteInfoProvider>
	);
}

export default App;