import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const basename = process.env.REACT_APP_BASE_URL || '/';

root.render(
	<Router basename={basename}>
		<App />
	</Router>
);
