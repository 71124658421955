import React, { createContext, useContext, useState, useEffect } from 'react';

const SiteInfoContext = createContext(undefined);

const SiteInfoProvider = ({ children }) => { // Default exported provider
	const [siteInfo, setSiteInfo] = useState({
		name: '',
		description: '',
		iconUrl: ''
	});

	useEffect(() => {
		// Define the async function
		const fetchSiteInfo = async () => {
			try {
				const response = await fetch(global.config["globals"].WpApiUrl);
				const data = await response.json();
				let siteIconUrl = '';

				if (data.site_icon) {
					const iconResponse = await fetch(`${global.config["globals"].WpApiUrl}/wp/v2/media/${data.site_icon}`);
					const iconData = await iconResponse.json();
					siteIconUrl = iconData.source_url;
				}

				setSiteInfo({
					name: data.name,
					description: data.description,
					iconUrl: siteIconUrl
				});
			} catch (error) {
				console.error('Error fetching site info:', error);
			}
		};

		// Invoke and explicitly handle the promise
		fetchSiteInfo().catch(error => {
			// Additional error handling if needed
			console.error('Error in useEffect fetchSiteInfo:', error);
		});

	}, []);

	return (
		<SiteInfoContext.Provider value={siteInfo}>
			{children}
		</SiteInfoContext.Provider>
	);
};

export const useSiteInfo = () => useContext(SiteInfoContext);
export default SiteInfoProvider; // Default export