import React, {} from "react";
import './Logo.css';
//import { Link } from "react-router-dom";


const Logo = () => {
	return (
		<>
			<div className={'logo-wrap'}>
				<svg className={'logo'} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
					<rect width="40" height="200"></rect>
					<rect x="80.13" width="39.87" height="200"></rect>
					<rect x="160" width="40" height="200"></rect>
					<rect width="200" height="40"></rect>
					<rect y="160" width="120" height="40"></rect>
				</svg>
				{/*<span className={'site-title'}>Deceptive Media</span>*/}
			</div>
		</>
	);
};

export default Logo;