import React from 'react';
import './footer.css';
import { useSiteInfo } from "../WPImport/SiteInfoContext";
import SocialLinks from "../SocialLinks";
import ThemeSwitcher from "../ThemeSwitcher";

const Footer = ({ className }) => {


	//let currentYear = new Date().getFullYear()
	const siteInfo = useSiteInfo();

	return (
		<footer className={`${className} footer`}>
			<SocialLinks />
			<ThemeSwitcher />
			<div className={"copy"}>
				&copy; {new Date().getFullYear()} {siteInfo.name}
			</div>
		</footer>
	);
}

export default Footer;